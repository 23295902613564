<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <KTHeaderMobile></KTHeaderMobile>

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div><topnav></topnav></div>
    <div
      :class="
        'd-flex flex-row flex-column-fluid page' +
          (toggle_aside ? '' : ' pl-30')
      "
      style="position:relative"
    >
      <!-- {{ toggle_aside }} -->
      <KTAside v-if="asideEnabled" @toggle="toggleClass"></KTAside>

      <div
        id="kt_wrapper"
        class="d-flex flex-column flex-row-fluid wrapper pl-0"
      >
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- @click="hideAside" -->

          <!-- <topnav></topnav> -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <div class="container-fluid">
            <headerdropdowns v-show="isFilterEnable"></headerdropdowns>
          </div>

          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': !contentFluid,
                container: contentFluid
              }"
            >
              <transition name="fade-in-up">
                <router-view />
              </transition>
              <chatbubble />
            </div>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>
    <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import headerdropdowns from "@/view/layout/subheader/HeaderDropdowns.vue";
import topnav from "@/view/layout/subheader/TopNav.vue";
// import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import chatbubble from "./ChatBubble";
export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeaderMobile,
    KTFooter,
    headerdropdowns,
    topnav,
    KTSubheader,
    chatbubble,
    // KTStickyToolbar,
    KTScrollTop,
    Loader
  },
  data() {
    return {
      toggle_aside: false
    };
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    if (this.isTokenExpiredGetter) {
      this.showMsg();
    }
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {
    toggleClass(val) {
      this.toggle_aside = val;
      // this.$forceUpdate();
    },
    showMsg() {
      this.$swal
        .fire({
          icon: "error",
          title: "Your session has expired!",
          text: "Please login to continue",
          confirmButtonText: "Go to login page",
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$router.push({ name: "login" });
          }
        });
    },
    hideAside() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "aside-minimize");
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "isTokenExpiredGetter"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    isFilterEnable() {
      return this.$store.getters.isFilterEnable;
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  },
  watch: {
    isTokenExpiredGetter(newValue) {
      if (newValue) {
        this.showMsg();
      }
    }
  }
};
</script>
